import './assets/css/vendor/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';


const render = () => {
  import('./assets/css/sass/themes/lector-ami-theme.scss').then((x) => {
    require('./AppRenderer');
  });
};

render();