import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  signIn,
  forgotPassword as forgotPasswordAPI,
  resetPassword as resetPasswordAPI,
} from '../../api/auth.api';

import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordError,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await signIn(email, password);
  return response.data;
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const data = yield call(loginWithEmailPasswordAsync, email, password);
    if (!data.message) {
      localStorage.setItem('access_token', data.access_token);
      yield put(loginUserSuccess(data.user));
      history.push('/');
    } else {
      yield put(loginUserError(data.message));
    }
  } catch (error) {
    if (error.response) {
      yield put(loginUserError(error.response.data.message));
    } else if (error.request) {
      yield put(loginUserError('Algo salio mal, intentalo de nuevo!'));
    } else {
      yield put(
        loginUserError('Parece que ha ocurrido un error, intentalo más tarde'),
      );
    }
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout() {
  try {
    localStorage.removeItem('access_token');
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await forgotPasswordAPI(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);

    if (forgotPasswordStatus.status === 200) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(
        forgotPasswordError(forgotPasswordStatus.response.data.message),
      );
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (newPassword, token) => {
  return resetPasswordAPI({ newPassword, token })
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, token } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      newPassword,
      token,
    );
    if (resetPasswordStatus.status === 200) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.response.data.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
