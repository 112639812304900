export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const BASE_URL = process.env.REACT_APP_API_PORT ? `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api` : `${process.env.REACT_APP_API_HOST}/api`;
export const RESOURCE_URL = process.env.REACT_APP_API_PORT ? `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/uploads/` : `${process.env.REACT_APP_API_HOST}/uploads/`;

export const roles = {
  ADMINISTRATOR: 'Administrador',
  TEACHER: 'Profesor',
  ESTUDENT: 'Estudiante',
};

export const dateWeekDayNameList = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
];
export const weekdayList = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
];
export const hourPeriodList = [
  '6:00 - 7:00',
  '7:00 - 8:00',
  '8:00 - 9:00',
  '9:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
];

export const bookingTypeList = ['Monitoria', 'Tutoría'];
