import axios from 'axios';
import { BASE_URL } from '../constants/defaultValues';
export const LOGIN_URL = `${BASE_URL}/auth/sign-in`;
export const VERIFY_TOKEN_URL = `${BASE_URL}/auth/verify`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/auth/forgot-password`;
export const RESET_PASSWORD_URL = `${BASE_URL}/auth/reset-password`;

export function signIn(email, password) {
  return axios.post(
    LOGIN_URL,
    {},
    {
      auth: {
        username: email,
        password,
      },
    },
  );
}

export function forgotPassword(email) {
  return axios.post(FORGOT_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(data) {
  return axios.post(RESET_PASSWORD_URL, data);
}

export function verifyToken(token) {
  return axios.post(VERIFY_TOKEN_URL, { token });
}
